import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';

import {ErrorsComponent} from './errors.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AccountErrorComponent} from './account-error/account-error.component';
import {AccountDisabledComponent} from './account-disabled/account-disabled.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';

const routes = [
    {
        path: '',
        component: ErrorsComponent,
        children: [
            {path: 'disabled', component: AccountDisabledComponent},
            {path: 'error', component: AccountErrorComponent},
            {path: 'maintenance', component: MaintenanceComponent},
            {path: '**', component: PageNotFoundComponent},
        ]
    }
];

@NgModule({
    declarations: [
        PageNotFoundComponent,
        ErrorsComponent,
        AccountErrorComponent,
        AccountDisabledComponent,
        MaintenanceComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        TranslateModule,
    ]
})
export class ErrorsModule {
}
