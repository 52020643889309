<div class="app flex-row align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body p-3 text-center">
                        <img src="assets/img/brand/logo.png" class="img-fluid mx-auto d-block pt-3" alt="NoteRadar">
                        <div class="pt-2 pb-3">
                            <router-outlet/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
